import React from "react";
import { StaticQuery, graphql } from "gatsby";

export const query = graphql`
  query ServicesQuery {
    services: allSanityService(limit: 100) {
      edges {
        node {
          icon {
            ...figure
          }
          title
          description
        }
      }
    }
  }
`;

const Service = (props) => {
  const {title, icon, description} = props;
  return (
    <div className="flex flex-col items-center">
        <div className="w-24 md:w-32 h-24 md:h-32 mb-2 md:mb-4">
          <img src={icon.asset.url} loading="lazy" alt={icon.alt} className="w-full h-full object-center"/>
        </div>

        <div>
          <div className="text-indigo-500 md:text-xl font-bold text-center mb-3 italic">{title}</div>
          <p className="text-gray-500 text-sm md:text-base text-left mb-3 md:mb-4">{description}</p>
        </div>
      </div>
  )
};

const Services = () => {
  return (
  <StaticQuery
    query={query}
    render={(data) => {
      const services = data.services.edges.map((edge, i) => <Service {...edge.node} key={`services-${i}`}/>);

      return (
        <div className="bg-white py-6 sm:py-8 lg:py-12">
          <div className="max-w-screen-xl px-4 md:px-8 mx-auto">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4 lg:gap-x-8 gap-y-8 lg:gap-y-12">
              {services}
            </div>
          </div>
        </div>
      )
      }
    }
  />)
};

export default Services;
