import React from "react";
import {ReactSVG } from 'react-svg';
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

import * as styles from "./figure.module.css";

function Figure(props) {
  let { node } = props;
  if (!node) {
    node = props;
  }
  if (!node?.asset) {
    return null;
  }
  const imageData = getGatsbyImageData(node.asset, { maxWidth: 675 }, clientConfig.sanity);
  console.log(props);

  if (props.asset.extension === 'svg') {
    return (
      <figure className="text-center">
        <img className="my-0 mx-auto" src={props.asset.url} alt={props.alt} style={{maxWidth: props.asset.metadata.dimensions.width}} />
      </figure>
    )
  }

  return (
    <figure className={styles.root}>
      <GatsbyImage image={imageData} alt={node.alt} />
      {node.caption && <figcaption>{node.caption}</figcaption>}
    </figure>
  );
}

export default Figure;
