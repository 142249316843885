import React from "react";
import { white } from "tailwindcss/colors";

const Cta = (props) => {
  let {children, title, scroll, link, color='magicite', bgColor='transparent', hoverColor= 'white', hoverBg='magicite'} = props;

  if (props.displayType === 'secondary') {
    color = 'white';
    hoverColor = 'magicite';
    bgColor = 'transparent';
    hoverBg = 'white';
  }
  // text-magicite
  // bg-magicite
  // border-magicite
  // text-white
  // border-white
  // bg-white
  // bg-transparent
  // hover:text-magicite
  // hover:text-white
  // hover:bg-white
  // hover:bg-magicite
  return (
    <a
      href={`#${scroll.title}`}
      className={`inline-block bg-${bgColor} hover:bg-${hoverBg} focus-visible:ring ring-${color} text-${color} active:text-${hoverColor} hover:text-${hoverColor} border border-${color} hover:border-transparent text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3`}>
      {children || title}
    </a>
)};

export default Cta;
