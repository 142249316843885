import React from "react";
import Icon from '../icon';
import Text from '../Text';
import Cta from "../Cta";

import { Parallax } from 'react-parallax';

const BackgroundImage = ({image}) => (
  <img src={image?.url} loading="lazy" alt={image?.title} className="w-full h-full object-cover object-center absolute inset-0"/>
);

const ImageHeading = ({image, heading}) => (
  <div className="sm:max-w-3xl flex flex-col items-center relative p-4">
    <div className="w-full flex flex-col sm:flex-row sm:justify-center gap-2.5 max-w-lg sm:max-w-xs md:max-w-md lg:max-w-lg">
      <img src={image?.url} alt={image?.title} />
    </div>
    <h1 className="text-white text-2xl sm:text-xl md:text-2xl text-center mb-8 md:mb-12 mt-8">{heading}</h1>
  </div>
);

const ImageHeadingText = ({image, heading, text, ctas}) => {
  console.log('IMAGE HEADING TEXT', image);
  return (
  <div className="sm:max-w-3xl flex flex-col items-center relative p-4">
    <div className="flex flex-col sm:flex-row sm:justify-center gap-2.5 mb-8">
      <img src={image?.url} alt={image?.title} width={image?.metadata?.dimensions?.width} />
    </div>
    <h2 className="text-white text-2xl sm:text-xl md:text-2xl font-bold text-left mb-6 max-w-lg">{heading}</h2>
    {text}
    {ctas && ctas.map((cta, i) => <Cta {...cta} key={`hero-cta-${i}`} />)}
  </div>
)};

const NoImage = ({heading, text, ctas}) => {
  return (
    <div className="sm:max-w-3xl flex flex-col items-center relative p-4 ">
      <h1 className="max-w-3xl md:max-w-2xl text-white text-2xl sm:text-xl md:text-3xl font-bold text-center mt-16 md:mt-24 mb-4 md:mb-8">{heading}</h1>
      {text}
      {ctas && ctas.map((cta, i) => <Cta {...cta} key={`hero-cta-${i}`} />)}
    </div>
  )
}



const Hero = ({variant, heading, inlineImage, backgroundImage, ctas, _rawTagline}) => {
  console.log('HERO VARIANT', variant, ctas);
  const defaultClasses = "min-h-96 flex justify-center items-center flex-1 flex-shrink-0 overflow-hidden shadow-lg rounded-lg relative ";
  const variantClasses = {
    'image-heading': "pt-16 pb-8 md:pt-20 md:pb-12 xl:pt-40 xl:pb-24",
    'image-heading-text': "py-8"
  };

  return (<>
      <div className={`shadow-lg -mx-3 sm:mx-0 ${variant === 'image-heading-text' && 'mt-24'}`}>
        <Parallax
          bgImage={backgroundImage?.asset.url}
          bgImageAlt={backgroundImage?.title}
          strength="360">
          <div className={`${defaultClasses} ${variantClasses[variant]}`}>
            {variant === 'image-heading' && <ImageHeading image={inlineImage?.asset} heading={heading}/>}
            {variant === 'image-heading-text' && <ImageHeadingText image={inlineImage?.asset} heading={heading} text={<Text classes="text-white text-xl max-w-lg mx-auto my-4 text-left" _rawText={_rawTagline} />} ctas={ctas}/>}
            {variant === 'no-image' && <NoImage heading={heading} text={<Text classes="text-white text-xl max-w-3xl md:max-w-2xl px-6 md:px-0 mx-auto mb-16 md:mb-24 text-center" _rawText={_rawTagline} />} ctas={ctas}/>}
          </div>
        </Parallax>
      </div>

      <div className="w-14 mt-12 mb-6 mx-auto">
        <Icon symbol="magicite"/>
      </div>

    </>
  )
}

export default Hero;
