import React from "react";

import Hero from './hero';
import Text from './Text';
import Figure from './figure';
import Cta from './Cta';
import Services from './Services';
import TeamMembers from './TeamMembers';
import Break from "./Break";

const typeNameMapping = {
  'SanityHero': Hero,
  'SanityTextSection': Text,
  'SanityFigure': Figure,
  'SanityCta': Cta,
  'SanityTeamPlaceholder': TeamMembers,
  'SanityServicesPlaceholder': Services,
  'SanityBreakLine': Break
};

const Content = ({components}) => {
  return components.map((component, i) => {
    var MappedComponent = typeNameMapping[component.__typename];
    return <MappedComponent {...component} key={`mapped-${i}`}/>;
  })
}

const Section = ({title, content}) => {
  return (
    <section id={title} className="text-center">
      <Content components={content}/>
    </section>
  )
}

export default Section;
