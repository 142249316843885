import React from "react";
import BlockText from "./block-text";

const Text = (props) => {
  const text = props._rawText?.map((txt, i) => <BlockText blocks={txt} key={i}/>);
  const classes = props.classes || "w-auto md:w-7/12 w- mx-auto mb-4 text-left";
  return (
    <div className={classes}>
      {props.label && <h4 className="text-center">{props.label}</h4>}
      {props.heading && <h2 className="text-center">{props.heading}</h2>}
      {text}
    </div>)
}

export default Text;
