
import React from 'react';
import EmbedHTML from './EmbedHTML'
import Figure from './figure';

const figcaption = ({children}) => (<figcaption>{children}</figcaption>);
const magicite = ({children}) => (<span style={{color: '#32689b'}}>{children}</span>)

const serializers = {
  types: {
    embedHTML: EmbedHTML,
    figure: Figure
  },
  marks: {figcaption}
}

export default serializers
