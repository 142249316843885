import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Text from './Text';

export const query = graphql`
  query TeamMembersQuery {
    teamMembers: allSanityTeamMember(limit: 100) {
      edges {
        node {
          image {
            ...figure
          }
          name
          _rawBio(resolveReferences: {maxDepth: 5})
        }
      }
    }
  }
`;

const TeamMember = (props) => {
  const {name, image, _rawBio} = props;
  return (
    <div className="flex flex-col items-center">
        <div className="w-24 md:w-32 h-24 md:h-32 mb-2 md:mb-4 rounded-full overflow-hidden">
          <img src={image.asset.url} loading="lazy" alt={image.alt} className="w-full h-full object-cover object-center"/>
        </div>

        <div>
          <div className="text-indigo-500 md:text-xl font-bold text-center mb-3 italic">{name}</div>
          <Text classes="text-gray-500 text-sm md:text-base text-left mb-3 md:mb-4" _rawText={_rawBio} />
        </div>
      </div>
  )
};

const TeamMembers = () => {
  return (
  <StaticQuery
    query={query}
    render={(data) => {
      const team = data.teamMembers.edges.map((edge, i) => <TeamMember {...edge.node} key={`team-${i}`}/>);

      return (
        <div className="bg-white py-6 sm:py-8 lg:py-12">
          <div className="max-w-screen-xl px-4 md:px-8 mx-auto">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4 lg:gap-x-8 gap-y-8 lg:gap-y-12">
              {team}
            </div>
          </div>
        </div>
      )
      }
    }
  />)
};

export default TeamMembers;
