import React from "react";
import Icon from './icon';

const Break = () => {
  return (
    <>
      <hr className="border-t-2 border-gray-200 my-20 shadow-xl"></hr>
      <div className="w-14 mb-6 mt-0 mx-auto">
          <Icon symbol="magicite"/>
        </div>
    </>
)};

export default Break;
